/*
  BRAND LEVEL THEME
  If a project has multiple sites that have shared theming, those styles can be placed here.
  If a site needs to override a brand level style, that can be done by writing the desired override in the site level theme.js file.
*/
export default {
  colors: {
    primary: '#003595',
    secondary: '#00AE42',
    tertiary: '#93D500',
    dark: '#000000',
    light: '#f5f5f6',
    warning: '#FF7928',
    error: '#FF3068',
    success: '#2DE6BC',
  },
  alternateBgColor: true,
  backgroundColors: {
    primary: {
      textColor: '#FFFFFF',
    },
    dark: {
      textColor: '#FFFFFF',
    },
    white: {
      textColor: '#000000',
    },
    light: {
      textColor: '#000000',
    },
  },
  fonts: {
    headings: {
      family: 'Helvetica Neue LT Pro Md, sans-serif',
      sm: {
        weight: 400,
      },
      md: {
        weight: 400,
      },
      lg: {
        weight: 400,
      },
    },
    subheadings: {
      family: 'Helvetica Neue LT Pro Md, sans-serif',
      sm: {
        weight: 400,
      },
      md: {
        weight: 400,
      },
      lg: {
        weight: 400,
      },
    },
    body: {
      family: 'Helvetica Neue LT Pro Roman, sans-serif',
    },
  },
  components: {
    Button: {
      rounded: 'min',
    },
    PackageCard: {
      radius: '8px',
      stroke: {
        width: '3px',
        style: 'solid',
        color: '#003595',
      },
      label: {
        inside: true,
        backgroundColor: '#93D500',
        color: '#003595',
        radius: '28px',
      },
    },
  },
  radius: '8px',
}
